$(document).ready(function () {
    'use strict';
    $('#use-shipping-address-form input[type=radio]').on('click', function (event) {
        var $this = $(this);
        var $target = $($this.data('target'));
        var $copyFrom = $($this.data('copy-from'));
        if ($this.data('action') === 'show') {
            try {
                localStorage.setItem('checkoutBillingOption', 'detail');
            } catch (e) { }
            $target.slideDown();
            $('#cfpiva').removeAttr('data-abide-ignore');
        } else {
            try {
                localStorage.setItem('checkoutBillingOption', 'quick');
            } catch (e) { }
            $(':input[name]', $copyFrom).each(function () {
                $('[name=' + $(this).attr('name') + ']', $target).val($(this).val())
            });
            $target.slideUp();
            $('#cfpiva').attr('data-abide-ignore', 'data-abide-ignore');
        }
    });

    $(".burn-coupon-form").submit(function (event) {
        event.preventDefault();
        if ($(this).find("input[name=code]").val().length > 0) {
            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                data: $(this).serialize(),
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_COUPON_VALID']);
                        window.location.href = "/current/cart";
                    } else {
                        showMessageBox('danger', msg['MSG_COUPON_ERROR']);
                    }
                }
            });
        } else {
            showMessageBox('danger', msg['MSG_COUPON_ERROR']);
        }
    });

    function submitAddressEditForm(form) {
        var href = form.data('nextstep');
        return $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            headers: { 'Accept': "application/json" },
            success: function (data) {
                if (data.success && data.data === 'VAT_CHANGED') {
                    href = '//' + location.host + location.pathname + '?warning=msg.order.vat.changed';
                }
            },
            error: function (xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    }

    function stepToShippingOptions() {

    }

    var dispatchForm = $('#dispatch-address-form');
    var billingForm = $('#billing-address-form');
    $('.checkout-save-button[data-validate-form="#dispatch-address-form"]').on('click', function (event) {
        event.preventDefault();

        if ($('#dispatch-address-form.physical-shipping').length > 0) {
            var useShipping = $('#use-shipping');
            var useBilling = $('#diff-shipping');

            if (!$('#clausola1').is(':checked')) {
                showMessageBox('danger', msg['ERR_PRIVACY']);
                $('#clausola1 + label').addClass('is-invalid-label');
                return;
            }
            if (useShipping.is(':checked') || useBilling.is(':checked')) {
                // trigger event manually to clone the form
                try {
                    window.sessionStorage.setItem("addressee", document.getElementById("addressee").value);
                    window.sessionStorage.setItem("street", document.getElementById("street").value);
                    window.sessionStorage.setItem("number", document.getElementById("number").value);
                    var country = document.getElementById("country");
                    window.sessionStorage.setItem("country", country.options[country.selectedIndex].text);
                    window.sessionStorage.setItem("city", document.getElementById("city").value);
                    window.sessionStorage.setItem("prov", document.getElementById("prov").value);
                    window.sessionStorage.setItem("cap", document.getElementById("cap").value);
                    window.sessionStorage.setItem("cel_prefix", document.getElementById("cel_prefix").value);
                    window.sessionStorage.setItem("cel", document.getElementById("cel").value);
                } catch (e) {
                    //If in ingognito we can't save data in sessionStorage
                }
                if (useShipping.is(':checked')) {
                    useShipping.trigger('click');
                }
            }

            dispatchForm.foundation("validateForm");
        }

        if ($('#dispatch-address-form.virtual-shipping').length > 0) {

            var senderValue = jQuery('input[name=giftcard_sender]').val();
            var recipientValue = jQuery('input[name=giftcard_recipient]').val();
            var textValue = jQuery('input[name=giftcard_text]').val();
            var dateValue = jQuery('input[name=giftcard_date]').val();
            var emailValue = jQuery('input[name=email]').val();
            var countryValue = jQuery('input[name=country_id]').val();

            $.ajax({
                url: "/restful/shopping/public/checkout/setproperty",
                method: "POST",
                data: {
                    key: 'giftcard_sender',
                    value: senderValue
                },
                success: function () {
                    $.ajax({
                        url: "/restful/shopping/public/checkout/setproperty",
                        method: "POST",
                        data: {
                            key: 'giftcard_recipient',
                            value: recipientValue
                        },
                        success: function () {
                            $.ajax({
                                url: "/restful/shopping/public/checkout/setproperty",
                                method: "POST",
                                data: {
                                    key: 'giftcard_text',
                                    value: textValue
                                },
                                success: function () {
                                    $.ajax({
                                        url: "/restful/shopping/public/checkout/setproperty",
                                        method: "POST",
                                        data: {
                                            key: 'giftcard_date',
                                            value: dateValue
                                        },
                                        success: function () {
                                            $.ajax({
                                                url: "/restful/shopping/public/checkout/saveshipping",
                                                method: "POST",
                                                headers: { 'Accept': "application/json" },
                                                data: {
                                                    email: emailValue,
                                                    country_id: countryValue,
                                                    street: '-',
                                                    number: '-',
                                                    cap: '10100',
                                                    city: '-',
                                                    addressee: '-',
                                                    state: '-'

                                                },
                                                success: function () {
                                                    dispatchForm.foundation("validateForm");
                                                },
                                                error: function () {
                                                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                                                }
                                            });
                                        },
                                        error: function () {
                                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                                        }
                                    });
                                },
                                error: function () {
                                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                                }
                            });
                        },
                        error: function () {
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        }
                    });

                },
                error: function () {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            });

        }
    });

    // form validation failed
    dispatchForm.bind("forminvalid.zf.abide", function (e, target) {
        showMessageBox('danger', msg['ERR_MISSINGFIELDS']);
    });

    dispatchForm.bind("formvalid.zf.abide", function (e, target) {
        if ($('#dispatch-address-form.physical-shipping').length > 0) {
            billingForm.foundation("validateForm");
        } else {
            if (window.dataLayer) {
                dataLayer.push({ ecommerce: null });
                window.pushGTMEventWithCallback(500, {
                    event: 'add_shipping_info',
                    ecommerce: window.ecommerce,
                    eventCallback: () => {
                        window.location.href = "/current/checkout/payment";
                    }
                });
            }
            else {
                window.location.href = "/current/checkout/payment";
            }
        }
    });
    billingForm.bind("formvalid.zf.abide", function (e, target) {
        $.when(submitAddressEditForm(dispatchForm), submitAddressEditForm(billingForm)).done(function (s1, s2) {
            if ($('#dispatch-address-form.physical-shipping').length > 0) {
                if (window.dataLayer) {
                    dataLayer.push({ ecommerce: null });
                    window.pushGTMEventWithCallback(500, {
                        event: 'add_shipping_info',
                        ecommerce: window.ecommerce,
                        eventCallback: () => {
                            window.location.href = $('.checkout-save-button').attr('href').toString();
                        }
                    });
                }
                else {
                    window.location.href = $('.checkout-save-button').attr('href').toString();
                }
                // stepToShippingOptions();
            }
        })
    });

    function checkPreferredBillingOption() {
        try {
            var layout = localStorage.getItem('checkoutBillingOption');
            if (layout && layout === 'detail') {
                var checkbox = $('#diff-shipping');
                if (checkbox.prop('disabled') === false) {
                    checkbox.trigger('click');
                }
            } else {
                var checkbox = $('#use-shipping');
                if (checkbox.prop('disabled') === false) {
                    checkbox.trigger('click');
                }
            }
        }
        catch (e) {
            var checkbox = $('#use-shipping');
            if (checkbox.prop('disabled') === false) {
                checkbox.trigger('click');
            }
        }
    }

    $('.payment-form [type="submit"]').on('click', function(event) {
        event.preventDefault();

        const button = this;
        if (window.dataLayer) {
            dataLayer.push({ ecommerce: null });
            window.pushGTMEventWithCallback(500, {
                event: "add_payment_info",
                ecommerce: window.ecommerce(button.closest('form').dataset.type),
                eventCallback: function() {
                    button.closest('form').requestSubmit();
                }
            });
        }
        else {
            button.closest('form').requestSubmit();
        }
    });

    // refresh country
    $('#dispatch-address-form #country').on('change', function () {
        const countryInput = $(this);
        const oldCountry = $('#country_id').val();
        const newCountry = countryInput.val();

        const form = $('#dispatch-address-form');
        const cartSummary = $('.cart-summary');

        function resetCountry() {
            countryInput.val(oldCountry);
            updateProvinces();
        }

        showQuestionBox(msg['MSG_COUNTRY_CHANGE_COST_WARNING'],
            function () {
                $('#country_id').val(newCountry);

                $.ajax({
                    type: form.attr('method'),
                    url: form.attr('action'),
                    data: form.serialize(),
                    headers: { 'Accept': "application/json" },
                    beforeSend: function () {
                        cartSummary.addClass('loading');
                    },
                    success: function (data) {
                        if (data.success) {
                            cartSummary.parent().load(window.location + '?' + new Date().getTime() + ' .cart-summary:lt(1)');
                        }
                        else if (data.errors.message == 'ERR_INVALIDMAIL') {
                            showMessageBox('danger', msg['ERR_SHIPPING_COUNTRY_INVALIDMAIL']);
                            resetCountry();
                        }
                        else {
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                            resetCountry();
                        }
                    },
                    error: function () {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        resetCountry();
                    },
                    complete: function () {
                        cartSummary.removeClass('loading');
                    }
                });
            },
            resetCountry
        );
    });

    $('.carrier-form').change(function () {
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: {
                country_id: $(this).find('select[name=country_id]').val(),
                carrier_id: $(this).find('input[name=carrier_id]:checked').val() != null ? $('input[name=carrier_id]:checked').val() : 0
            },
            headers: { 'Accept': "application/json" },
            success: function (data) {
                if (data.success) {
                    $('.cart-summary').load(window.location.href + ' .cart-summary', function () {
                        if (typeof callback !== 'undefined') {
                            callback();
                        }
                    });
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function (xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    //checkPreferredBillingOption();

    $('[name*="country"]').on('change', updateProvinces);
    updateProvinces();

    function updateProvinces() {
        $('[name*="prov"]').each(function () {
            const $this = $(this);
            const country = $this.closest('form').find('[name*="country"]');

            if (country.length > 0) {
                loadProvinces(country.val()).then(function (provinces) {
                    if (provinces.length > 0) {
                        const id = $this.attr('id');
                        const name = $this.attr('name');
                        const classes = $this.attr('class');
                        const type = $this.attr('type');
                        const value = $this.val();
                        const placeholder = $this.attr('placeholder');
                        let options = [];
                        let hasSelectedValue = false;

                        for (let i = 0; i < provinces.length; i++) {
                            const province = provinces[i];
                            const selected = (province.code == value) ? 'selected' : '';
                            options.push(`<option value="${province.code}" ${selected}>${province.name} (${province.code})</option>`);
                            hasSelectedValue = hasSelectedValue || (province.code == value);
                        }
                        if (!hasSelectedValue) {
                            options[0].replace('  ', ' selected');
                        }
                        $this.replaceWith(`
                            <select id="${id}" class="${classes}" name="${name}" data-type="${type}" data-placeholder="${placeholder}" data-value="${value}" required aria-required="true">
                                ${options.join('\n')}
                            </select>
                        `);
                    }
                    else if ($this.is('select')) {
                        const id = $this.attr('id');
                        const name = $this.attr('name');
                        const classes = $this.attr('class');
                        const type = $this.data('type');
                        const value = $this.data('value');
                        const placeholder = $this.data('placeholder');
                        $this.replaceWith(`<input id="${id}" class="${classes}" type="${type}" name="${name}" value="${value}" placeholder="${placeholder}" required aria-required="true"/>`);
                    }
                });
            }
        });
    }
});
